import { connect } from 'react-redux';
import {
    updatePlayerStatus,
    updateLike,
    setSlideDialogOpen,
} from 'actions/music';
import TopSongs from 'components/TopSongs';
import { TOP_SONGS_PLAYLIST_ID } from 'constants/api';

import {
    IRootState,
    ISlideDialog
} from 'interfaces/index';

const mapStateToProps = (state: IRootState) => ({
    currentMusic: state.music.currentMusic,
    // @ts-ignore
    topSongs: state.music.playlists[TOP_SONGS_PLAYLIST_ID],
});

const mapDispatchToProps = (dispatch: any) => ({
    updateLike: (data: any) => dispatch(updateLike(data)),
    updatePlayerStatus: (isPlaying: boolean) => dispatch(updatePlayerStatus(isPlaying)),
    setSlideDialogOpen: (data: ISlideDialog) => dispatch(setSlideDialogOpen(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
// @ts-ignore
export default connector(TopSongs);
