import React, {
    useState,
    useEffect, 
    useRef 
} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import SideBar from 'containers/SideBar';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import { IPlanObject } from 'interfaces';
import {
    isDesktop, isMobile 
} from 'utils';
import Transition from './Transition';

import './MusicDetailDialog.scss';

interface IMusicDetailDialogProps {
    className?: string;
    containerProps?: IPlanObject;
    currentTrack?: IPlanObject;
    open?: boolean;
    // eslint-disable-next-line no-unused-vars
    onClose?: (event: object, reason: string)=> any;
    fullScreen?: boolean;
    sidebar?: boolean;
    title?: string;
    transitionComponent?: React.ReactElement | any;
    children: React.ReactNode;
    [key: string]: any;
}

function MusicDetailDialog(props: IMusicDetailDialogProps) {
    const {
        className = '',
        containerProps = {},
        currentTrack = {},
        open = false,
        onClose = ()=> {},
        fullScreen = false,
        sidebar = true,
        children,
        title = '',
        transitionComponent = Transition,
        ...restProps
    } = props;

    const rootClassName = 'music-detail-dialog';
    const muiDialogScrollPaperCss = '.MuiPaper-root.MuiDialog-paperScrollPaper';

    const dialogRef: any = useRef();

    const [isMoveToView, setMoveToView] = useState(false);

    useEffect(()=>{
        let dialogContentRoot: HTMLElement | null;
        const windowHeight = window.innerHeight;

        const handleScroll = ()=> {
            if (dialogContentRoot) {
                // in mobile sidebar is default below view, update css once scroll past 24px;
                const shouldMoveToView = dialogContentRoot.scrollTop > 24;
                setMoveToView(shouldMoveToView);
            }
        };

        const cleanup = () => {
            dialogContentRoot?.removeEventListener('scroll', handleScroll);
        };

        if (open) {

            if (windowHeight > 667) {
                return setMoveToView(true);
            }
            // delay a bit - dom element is not there yet
            setTimeout(()=>{
                dialogContentRoot = document.querySelector(`.${rootClassName} ${muiDialogScrollPaperCss}`);

                if (dialogContentRoot && isMobile) {
                    dialogContentRoot.addEventListener('scroll', handleScroll);
                }
            }, 500);
        } else {
            cleanup();
        }

        return cleanup;
    }, []);

    // scroll modal content to the top whenever track change
    useEffect(()=> {
        if (dialogRef && dialogRef.current) {
            const dialogContentRoot = dialogRef.current.querySelector(muiDialogScrollPaperCss);

            if (dialogContentRoot) {
                dialogContentRoot.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            }
        }
    }, [currentTrack]);

    const closeIcon = isDesktop
        ? <CloseIcon className='music-detail-dialog__icon-close' />
        : <KeyboardArrowDownIcon className='music-detail-dialog__icon-close'/>;

    const classNames = `${rootClassName} ${className}`.trim();

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen={fullScreen}
            className={classNames}
            ref={dialogRef}
            TransitionComponent={transitionComponent || undefined}
            scroll="paper"
            {...restProps}
        >
            <div 
                className="music-detail-dialog__wrap"
                {...containerProps}
            >
                <div className="music-detail-dialog__wrap-inner">
                    <DialogTitle className='music-detail-dialog__title'>
                        {title && (
                            <span >{title}</span>
                        )}
                        <IconButton 
                            className='music-detail-dialog__btn-close'
                            onClick={(e)=> { onClose(e, ''); }}
                        >
                            {closeIcon}
                        </IconButton> 
                    </DialogTitle>
                    <DialogContent className='music-detail-dialog__content'>
                        {children}
                    </DialogContent>
                </div>
            </div>
            {sidebar && (
                <SideBar 
                    currentTrack={currentTrack}
                    className={isMoveToView ? 'in-view' : undefined}
                />
            )}
        </Dialog>
    );
}

export default MusicDetailDialog;
