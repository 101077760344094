import IconButton from '@mui/material/IconButton';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { abbreviateNumber } from 'utils/formatters';
import { eventTracker } from 'utils/tracker';
import { findMusicDetails } from 'utils/index';
import { MUSIC_LIKE } from 'constants/trackingEvents';
import {
    ICurrentMusic, 
    IPlanObject 
} from 'interfaces';

import './LikeButton.scss';

interface ILikeButtonProps {
    className?: string;
    currentMusic: ICurrentMusic;
    // eslint-disable-next-line no-unused-vars
    updateLike: (arg: IPlanObject )=> any;
    enableTracking?: boolean;
    [key: string]: any;
}

function LikeButton(props: ILikeButtonProps) {
    const {
        className = '',
        currentMusic = {} as ICurrentMusic,
        updateLike = ()=>{},
        enableTracking = true,
    } = props;

    const {
        playlistId = '',
        musicId = '',
    } = currentMusic;

    let currentTrack = {} as IPlanObject;

    const handleLikeClick = (e: any)=> {
        e.stopPropagation();

        if (!currentTrack) {
            return;
        }

        // only send 'like' if not yet liked.
        if (currentTrack.liked) {
            return;
        }

        if (enableTracking) {
            eventTracker(MUSIC_LIKE, { musicId: currentMusic.musicId });
        }

        updateLike({
            musicId,
            liked: !currentTrack.liked,
            playlistId
        });
    };

    let likeIcon = <FavoriteBorderIcon />;

    if (musicId) {
        currentTrack = findMusicDetails(currentMusic);

        if (currentTrack && currentTrack.liked) {
            likeIcon = <FavoriteIcon color="error"/>;
        }
    }

    const classNames = `like-button ${className}`.trim();

    return (
        <div className={classNames}>
            <IconButton 
                className="miniPlayer__btn-like"
                color="inherit"
                onClick={handleLikeClick}
            >
                {likeIcon}
            </IconButton>
            <small>
                {abbreviateNumber(currentTrack?.likes || 0)}
            </small>
        </div>
    );
}

export default LikeButton;
