import MusicNoteIcon from '@mui/icons-material/MusicNote';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import IconButton from '@mui/material/IconButton';
import LikeButton from 'containers/LikeButton';
import { abbreviateNumber } from 'utils/formatters';

import {
    IPlanObject, ISlideDialog 
} from 'interfaces';

import './SideBar.scss';

interface ISideBarProps {
    className?: string;
    currentTrack: IPlanObject;
    // eslint-disable-next-line no-unused-vars
    setSlideDialogOpen: (arg: ISlideDialog) => any;
    [key: string]: any;
}


function SideBar(props: ISideBarProps) {
    const {
        className = '',
        currentTrack = {},
        setSlideDialogOpen,
    } = props;

    const {
        views = 0,
    } = currentTrack;

    const classNames = `side-bar ${className}`.trim();

    return (
        <ul className={classNames}>
            <li>
                <IconButton 
                    className="btn-more"
                    onClick={()=> setSlideDialogOpen({ isOpen: true })}
                    aria-label="more"
                >
                    <AddCircleOutlineIcon />
                </IconButton>
            </li>
            <li>
                <LikeButton />
            </li>
            <li>
                <MusicNoteIcon />
                <small>
                    {abbreviateNumber(views)}
                </small>
            </li>
        </ul>
    );
}

export default SideBar;
