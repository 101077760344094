import Cookies from 'js-cookie';
import {
    SONGX_COOKIE,
    SHARE_CONFIG
} from 'constants/index';
import { store } from 'store/index';


import {
    ICurrentMusic, IPlanObject, 
    ITrack
} from "interfaces";

export function getDeviceInfo() {
    const {
        userAgent 
    } = navigator;

    const ret = {
        isIos: false,
        isAndroid: false,
        isDesktop: false,
    };

    if (/android/i.test(userAgent)) {
        ret.isAndroid = true;
    }
    else if (/iPad|iPhone|iPod/.test(userAgent)) {
        ret.isIos = true;
    }

    ret.isDesktop = !ret.isAndroid && !ret.isIos;

    return ret;
}

export const deviceInfo = getDeviceInfo();

export interface IShareData {
    url: string;
    text: string;
    title: string;
    files?: any[];
}

export async function asyncShare(shareData = {} as IShareData) {
    let ret = '';
    try {
        if (!navigator.canShare) {
            // do something else
            return ret;
        }

        await navigator.share(shareData);
        ret = 'shared';
    } catch (err: any) {
        ret = err.message;
    }
    return ret;
}

export async function shareMusic(currentMusic = {} as IPlanObject) {
    try {
        const {
            musicId ='',
            playlistId = ''
        } = currentMusic as ICurrentMusic;

        let shareUrl = SHARE_CONFIG.url;

        if (playlistId) {
            shareUrl = `${SHARE_CONFIG.url}/${playlistId}`;

            if (musicId) {
                shareUrl = `${SHARE_CONFIG.url}/${playlistId}/${musicId}`;
            }
        }

        const shareOptions = {
            ...SHARE_CONFIG,
            url: shareUrl,
        };

        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const musicDetails = findMusicDetails(currentMusic as ICurrentMusic);

        if (musicDetails && musicDetails.musicName) {
            shareOptions.text = `Listen "${musicDetails.musicName}"`;

            await asyncShare(shareOptions);

            return shareOptions;
        }
    } catch(err) {
        throw err;
    }
    return undefined;
}


export const safeJsonStringify = (data: any) => {
    try {
        return JSON.stringify(data);
    } catch(err) {
        // console.log(err);
    }
    return '';
};

export const safeJsonParse = (data: any) => {
    try {
        return JSON.parse(data);
    } catch(err) {
        // console.log(err);
    }
    return undefined;
};

export const createValidationInitValues = (obj = {}) => {
    const ret = {} as IPlanObject;

    Object.entries(obj).forEach(([key = '', value = {} as any]) => {
        if (value ) {
            const valueType = value.type;
            if (valueType === 'string') {
                ret[key] = '';
            }
            else if (valueType === 'boolean') {
                ret[key] = false;
            }
        }
    });

    return ret;
};

export function mergeObjectArrays(...arrays: any[]) {
    // concat arrays
    const mergedArray = arrays.reduce((acc, current) => [...acc, ...current], []);
    // stringify array values
    const stringifiedArray = mergedArray.map(JSON.stringify);
    // create unique Set
    const uniqueSet = new Set(stringifiedArray);
    // convert back to array and revive object
    // @ts-ignore 
    return Array.from(uniqueSet).map(JSON.parse);
}

// eslint-disable-next-line consistent-return
export async function copyTextToClipboard(text = '') {
    try {
        let isSupportClipboardAPI = false;

        if ('clipboard' in navigator) {
        // @ts-ignore 
            const result = await navigator.permissions.query({ name: 'clipboard-write' });

            if (result.state === 'granted' || result.state === 'prompt') {
                isSupportClipboardAPI = true;
                return navigator.clipboard.writeText(text);
            }
        }

        if (!isSupportClipboardAPI) {
            const execCommandResult = document.execCommand('copy', true, text);
            return Promise.resolve(execCommandResult);
        }
    } catch (err) {
        throw err;
    }
}

export function getSonxCookie() {
    return safeJsonParse(Cookies.get(SONGX_COOKIE));
}
/**
 * updateCreationRecord
 * @description
 * check if user reach diary max creation limit
 * @returns {boolean}
 */
export function updateCreationRecord(musicId = '') {
    if (!musicId) {
        return false;
    }
    const songxCookie = getSonxCookie()|| {} as any;

    const createdMusics = Array.isArray(songxCookie.createdMusics)
        ? songxCookie.createdMusics
        : [];

    createdMusics.push(musicId);

    songxCookie.createdMusics = createdMusics;
    const newSongxCookie = safeJsonStringify(songxCookie) || '';

    // cookie auto expires in 1 day
    Cookies.set(SONGX_COOKIE, newSongxCookie, { 
        expires: 1, 
        secure: true, 
        sameSite: 'strict'
    });

    return true;
}

let metaTags: any;

export function updateMetaTag(opt = {} as IPlanObject) {
    const {
        tagName = '',
        content = '',
    } = opt;

    if (!tagName || !content) {
        return;
    }

    if (!metaTags) {
        metaTags = document.querySelectorAll('meta');  
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const tag of metaTags) {
        if (tag.getAttribute('property') === tagName) {
            tag.setAttribute('content', content);
            break;  
        }
    }
}

export function findMusicDetails(opt = {} as ICurrentMusic) {
    const {
        musicId = '',
        playlistId = '',
        index = 0,
    } = opt;

    let ret;

    const state = store.getState();

    const {
        music = { playlists: {} as IPlanObject }
    } = state;

    const playlist = music.playlists[playlistId];

    if (playlist) {
        const data = typeof index === 'number'
            ? playlist.musics[index]
            : playlist.find((item: ITrack) => item.musicId === musicId);

        if(data && data.musicId === musicId) {
            ret = data;
        }
    }

    return ret;
}

export function getPlaylistUpdateDto(playlistId = '') {
    const state = store.getState();

    let ret;

    const {
        music = { playlists: {} as IPlanObject }
    } = state;

    const playlist = music.playlists[playlistId];

    if (playlist && Array.isArray(playlist.musics)) {
        ret = {
            title: playlist.title,
            private: playlist.private,
            archieve: playlist.archieve,
            musicIds: playlist.musics.map((item: ITrack )=>item.musicId)
        };
    }

    return ret;
}

export function isEmptyObect(obj = {}) {
    return Object.keys(obj).length === 0;
}

/**
 * shuffleArray
 * @param {array} array 
 * @returns {array} new shuffled array
 * @description shuffle an array. ref: https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
 */
export function shuffleArray(array: any[] = []) {
    const clonedArray = [...array];
    const arrayLength = clonedArray.length;

    for (let i = arrayLength - 1; i > 0; i-= 1) {
        const j = Math.floor(Math.random() * (i + 1));
        [clonedArray[i], clonedArray[j]] = [clonedArray[j], clonedArray[i]];
    }

    return clonedArray;
}

export function getRandomItemFromArray(array: any[] = []) {
    return Array.isArray(array) && array[(Math.floor(Math.random() * array.length))];
}

export function shuffleIndices(array: any[] = []) {
    // Create an array of indices [0, 1, 2, ..., array.length - 1]
    const indices: number[] = Array.from(Array(array.length).keys());
    
    // Fisher-Yates (Knuth) Shuffle Algorithm to shuffle indices
    for (let i = indices.length - 1; i > 0; i-= 1) {
        const j = Math.floor(Math.random() * (i + 1));
        [indices[i], indices[j]] = [indices[j], indices[i]];
    }
    
    return indices;
}

export function isMobileCheck() {
    return /Mobi|Android|iPhone|iPad|iPod|Windows Phone/i.test(navigator.userAgent);
}
  
// eslint-disable-next-line import/no-mutable-exports
export let isMobile: boolean;
// eslint-disable-next-line import/no-mutable-exports
export let isDesktop: boolean;

export const initMobileCheck = ()=>{
    isMobile = isMobileCheck();
    isDesktop = !isMobile;
};

