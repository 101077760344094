import { connect } from 'react-redux';
import { updatePlayerStatus } from 'actions/music';
import Controls from 'components/MusicPlayer/Controls';

import { IRootState } from 'interfaces/index';

const mapStateToProps = (state: IRootState) => ({ currentMusic: state.music.currentMusic });

const mapDispatchToProps = (dispatch: any) => ({ updatePlayerStatus: (isPlaying: boolean) => dispatch(updatePlayerStatus(isPlaying)) });
// @ts-ignore
export default  connect(mapStateToProps, mapDispatchToProps)(Controls);
