import {
    LazyLoadImage, 
    ScrollPosition 
} from 'react-lazy-load-image-component';
import MoreButton from 'components/MoreButton';

interface IGalleryViewProps {
    musicName?: string;
    author?: string;
    imageSrc?: string;
    scrollPosition?: ScrollPosition;
    // eslint-disable-next-line no-unused-vars
    handleMore?: (arg: any)=> any;
}

function GalleryView(props: IGalleryViewProps) {
    const {
        musicName = '',
        author = '',
        imageSrc,
        scrollPosition,
        handleMore = ()=>{},
    } = props;

    return (
        <div className='song-list-item__song-detail--gallery'>
            <LazyLoadImage
                className="song-list-item__song-img" 
                src={imageSrc}
                alt={`cover art ${musicName}`}
                scrollPosition={scrollPosition}
            />
            <div className="song-list-item__section">
                <div className="song-list-item__description">
                    <h5 className="text-truncated">
                        {musicName}
                    </h5>
                    <small className="text-truncated">
                        {author}
                    </small>
                </div>
                <MoreButton onClick={handleMore} />
            </div>
        </div>
    );
}

export default GalleryView;
