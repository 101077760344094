import { CSSProperties } from 'react';
import {
    FacebookIcon,
    FacebookShareButton,
    TwitterShareButton,
    XIcon,
} from "react-share";
import ShareLink from 'components/ShareLink';
import * as tracker from 'utils/tracker';
import { DESKTOP_SHARE } from 'constants/trackingEvents';
import { SHARE_CONFIG } from 'constants/index';

import './ShareBar.scss';
import { 
    ICurrentMusic, 
    IPlanObject 
} from 'interfaces';

interface IShareBarProps {
    className?: string;
    currentMusic: ICurrentMusic | IPlanObject;
    iconSize?: number;
    roundIcon?: boolean;
    title?: string;
    bgStyle?: CSSProperties;
    [key:string]: any;
}

function ShareBar(props: IShareBarProps) {
    const {
        className = '',
        currentMusic = {} as ICurrentMusic,
        title = SHARE_CONFIG.title,
        iconSize = 48,
        roundIcon = true,
        bgStyle = { fill: 'transparent' }
    } = props;

    if (!currentMusic.playlistId) {
        return (
            <div className="share-bar--preload" />
        );
    }

    let shareUrl = SHARE_CONFIG.url;

    const {
        playlistId,
        musicId,
    } = currentMusic;
    
    if (playlistId) {
        shareUrl = `${SHARE_CONFIG.url}/${playlistId}`;

        if (musicId) {
            shareUrl = `${SHARE_CONFIG.url}/${playlistId}/${musicId}`;
        }
    }

    const handleClick = (type = '') => {
        tracker.event(DESKTOP_SHARE, {
            shareUrl,
            type
        });
    };

    const classNames = `share-bar ${className}`.trim();

    return (
        <div className={classNames}>
            <FacebookShareButton
                url={shareUrl}
                className="share-bar__fb"
                onClick={() => handleClick('Facebook')}
            >
                <FacebookIcon 
                    size={iconSize} 
                    round={roundIcon} 
                    bgStyle={bgStyle}
                />
            </FacebookShareButton>

            <TwitterShareButton
                url={shareUrl}
                title={title}
                className="share-bar__twitter"
                onClick={() => handleClick('Twitter')}
            >
                <XIcon 
                    size={iconSize} 
                    round={roundIcon} 
                    bgStyle={bgStyle}
                />
            </TwitterShareButton>
            <ShareLink 
                shareUrl={shareUrl}
                onclick={() => handleClick('sharelink')}
            />
        </div>
    );
}

export default ShareBar;
