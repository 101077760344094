import {
    useState,
    MouseEvent 
} from 'react';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {
    DEFAULT_PLAYLIST_TITLE, 
    VIEW_TOGGLE 
} from 'constants/index';

import './PlaylistToggle.scss';

interface IPlaylistToggleProps {
    className?: string;
    playlistTitle: string;
    // eslint-disable-next-line no-unused-vars
    onViewChange: (arg: string) => any;
}


function PlaylistToggle(props: IPlaylistToggleProps) {
    const {
        className = '',
        playlistTitle = '',
        onViewChange = ()=>{},
    } = props;

    const [view, setView] = useState(VIEW_TOGGLE.GALLERY);

    const handleChange = (event: MouseEvent<HTMLElement>, nextView: string) => {
        setView(nextView);
        onViewChange(nextView);
    };

    const playlistDisplayTitle = playlistTitle || DEFAULT_PLAYLIST_TITLE;

    const classNames = `${className} playlist-toggle`.trim();

    return (
        <div className={classNames}>
            <h3 className='playlist-toggle__title'>
                {playlistDisplayTitle}
            </h3>
            <ToggleButtonGroup
                className='playlist-toggle__view-toggle'
                value={view}
                exclusive
                onChange={handleChange}
            >
                <ToggleButton 
                    value={VIEW_TOGGLE.GALLERY}
                    aria-label="gallery view"
                >
                    <ViewModuleIcon />
                </ToggleButton>
                <ToggleButton 
                    value={VIEW_TOGGLE.LIST}
                    aria-label="list view"
                >
                    <ViewListIcon />
                </ToggleButton>
            </ToggleButtonGroup>
        </div>
    );
}

export default PlaylistToggle;
