import {
    LOGIN_PENDING,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    LOGOFF_SUCCESS,
    LOGOFF_ERROR,
    UPDATING_PROFILE,
    UPDATE_PROFILE_SUCCESS,
    UPDATE_PROFILE_ERROR,
    LOADING_USERS_PLAYLISTS,
    LOADING_USERS_PLAYLISTS_SUCCESS,
    LOADING_USERS_PLAYLISTS_ERROR,
    DELETE_PLAYLIST_SUCCESS,
} from 'constants/actionTypes';
import {
    ERROR,
    SUCCESS,
    LOADING,
    UPDATING,
} from 'constants/index';
import { IAccountState } from 'interfaces';

interface IAction {
    type: string,
    payload: any
}

export default (state = {} as IAccountState, action = {} as IAction) => {
    const payload = action.payload || {};

    switch (action.type) {
        case LOGIN_PENDING: {
            return {
                ...state,
                accountStatus: LOADING,
            };
        }
        case LOGIN_SUCCESS: {
            return {
                ...state,
                ...payload,
                authenticated: !!payload.user,
                accountStatus: SUCCESS,
            };
        }
        case LOGIN_ERROR:
        case LOGOFF_ERROR:
        case UPDATE_PROFILE_ERROR: {
            return {
                authenticated: false,
                accountStatus: ERROR,
            };
        }
        case LOGOFF_SUCCESS: {
            return { authenticated: false };
        }
        case UPDATING_PROFILE: {
            return {
                ...state,
                accountStatus: UPDATING,
            };
        }
        case UPDATE_PROFILE_SUCCESS: {
            if (state?.user?.id !== payload.id) {
                return state;
            }
            return {
                ...state,
                user: {
                    ...state.user,
                    ...payload
                },
                accountStatus: SUCCESS
            };
        }
        case LOADING_USERS_PLAYLISTS: {
            return {
                ...state,
                playlists: { ...state.playlists },
                playlistsStatus: LOADING
            };
        }
        case LOADING_USERS_PLAYLISTS_SUCCESS: {
            return {
                ...state,
                playlists: {
                    ...state.playlists,
                    ...payload,
                },
                playlistsStatus: SUCCESS
            };
        }
        case DELETE_PLAYLIST_SUCCESS: {
            // this is user's playlists state in account slice
            // same action type also tirgger in music slice
            if (!state.playlists[payload]) {
                return state;
            }
            const newPlaylists = { ...state.playlists };
            delete newPlaylists[payload];

            return {
                ...state,
                playlists: newPlaylists,
            };
        }
        case LOADING_USERS_PLAYLISTS_ERROR: {
            return {
                ...state,
                playlists: { ...state.playlists },
                playlistsStatus: ERROR
            };
        }
        default:
            return state;
    }
};
