import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import './Spinner.scss';

interface ISpinnerProps {
    className?: string;
}

function Spinner(props: ISpinnerProps) {
    const {
        className = '',
    } = props;

    const classNames = `spinner ${className}`;

    return (
        <Box 
            className={classNames}    
            sx={{ display: 'flex' }}
        >
            <CircularProgress />
        </Box>
    );
}

export default Spinner;
