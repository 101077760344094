/* eslint-disable import/prefer-default-export */
import { IPlanObject } from 'interfaces';
import { trackEvent } from "./firebase";

// tracker - a wraper for analytics tracking. Currently use firebase analutics
// If we change to different analytic provide. just import different tracking method


export const event = (...args: any[]) => {
    try {
        // @ts-ignore
        return trackEvent(...args);
    } catch (err) {
        // console.error('tracking error: ', err);
    }
    return undefined;
};

// eslint-disable-next-line arrow-body-style
export const eventTracker = (eventName: string, data?: IPlanObject) => {
    return data && data.musicId
        ? event(eventName, data)
        : undefined;
};
