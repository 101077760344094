import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import Grid from '@mui/material/Grid2';
import MusicPlayerControls from 'containers/MusicPlayerControls';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import IconButton from '@mui/material/IconButton';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LikeButton from 'containers/LikeButton';
import ProgressInputRange from 'components/MusicPlayer/ProgressInputRange';
import { isDesktop } from 'utils/index';
import { IPlanObject } from 'interfaces';
import VolumeControl from './VolumeControl';

interface IDesktopLayout {
    className: string;
    cover: string;
    musicName: string;
    author: string;
    views: string | number;
    duration: number;
    audioRef: React.RefObject<any>;
    progressInputRangeRef: React.RefObject<any>;
    progressTimeRef:  React.RefObject<any>;
    handleNext: ()=>any;
    handlePrevious: ()=>any;
    handleRepeat: ()=>any;
    handlesRepeatSingle:  ()=>any;
    handleShuffle: ()=>any;
    handleModalOpen: ()=>any;
    slideDialog: IPlanObject;
    // eslint-disable-next-line no-unused-vars
    setSlideDialogOpen: (arg: any) => any;
}

function DesktopLayout(props: IDesktopLayout) {
    const {
        className = '',
        cover = '',
        musicName = '',
        author = '',
        views = '',
        duration = 0,
        audioRef,
        progressInputRangeRef,
        progressTimeRef,
        handleNext,
        handlePrevious,
        handleRepeat,
        handlesRepeatSingle,
        handleShuffle,
        handleModalOpen = ()=>{},
        slideDialog = {},
        setSlideDialogOpen = ()=>{},
    } = props;

    useEffect(()=>{
        if (slideDialog && slideDialog.isOpen) {
            setSlideDialogOpen({ isOpen: false });
        }
        return ()=>{
            if (slideDialog && slideDialog.isOpen) {
                setSlideDialogOpen({ isOpen: false });
            }
        };
    },[]);

    const classNames = `desktop-layout ${className}`.trim();

    return (
        <div className={classNames}>
            <Container maxWidth="lg">
                <Grid container>
                    <Grid 
                        size={{ sm: 4 }}
                        className="miniPlayer__col-details" 
                    >
                        <div 
                            className="miniPlayer__music-img" 
                            style={{ backgroundImage: `url(${cover})` }}
                        />
                        <div className="miniPlayer__description">
                            <h5 className="text-truncated">
                                {musicName}
                            </h5>
                            <small className="text-truncated">
                                {author}
                            </small>
                            <div className="miniPlayer__listened">
                                <MusicNoteIcon />
                                {views}
                            </div>
                        </div>
                        <div className="miniPlayer__like-contianer">
                            {/* @ts-ignore */}
                            <LikeButton className="miniPlayer__like" />
                            <IconButton 
                                className="btn-more"
                                onClick={()=> setSlideDialogOpen({ isOpen: true })}
                                aria-label="more"
                            >
                                <AddCircleOutlineIcon />
                            </IconButton>
                        </div>
                    </Grid>
                    <Grid 
                        className="miniPlayer__controls"
                        size={{ sm: 4 }}
                    >   
                        <MusicPlayerControls 
                            showFullControls={isDesktop} 
                            handleNext={handleNext}
                            handlePrevious={handlePrevious}
                            handleRepeat={handleRepeat}
                            handlesRepeatSingle={handlesRepeatSingle}
                            handleShuffle={handleShuffle}
                        />
                        <ProgressInputRange {...{
                            className: 'mt-1',
                            progressInputRangeRef,
                            progressTimeRef,
                            audioRef,
                            duration
                        }} />
                    </Grid>
                    <Grid 
                        className="miniPlayer__col-right"
                        size={{ sm: 4 }}
                    >
                        <IconButton 
                            className="btn_full-screen"
                            aria-label="full screen mode"
                            onClick={handleModalOpen}
                        >
                            <AspectRatioIcon />
                        </IconButton>
                        <VolumeControl audioRef={audioRef} />
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default DesktopLayout;
