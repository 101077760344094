import Skeleton from '@mui/material/Skeleton';
import { Container } from '@mui/material';
import Grid from '@mui/material/Grid';

function LoadingSkeleton() {
    return (
        <Container 
            maxWidth="lg"
            className="miniPlayer__skeleton"
        >
            <Grid container>
                <Grid 
                    item
                    xs={8}
                    className="miniPlayer__col-details" 
                >
                    <Skeleton 
                        className="miniPlayer__music-img"
                        variant="rectangular"
                        sx={{ bgcolor: 'grey.800' }}
                        width={60}
                        height={60} 
                    />
                    <div className="miniPlayer__description">
                        <Skeleton 
                            variant="text"
                            sx={{ fontSize: '1rem', bgcolor: 'grey.800' }}
                            width={140}
                        />
                        <Skeleton 
                            variant="text"
                            sx={{ fontSize: '0.8125em', bgcolor: 'grey.800' }} 
                            width={80}
                        />
                    </div>
                </Grid>
                <Grid 
                    className="miniPlayer__controls"
                    item
                    xs={4}
                >
                    <Skeleton 
                        variant="circular"
                        sx={{ bgcolor: 'grey.800' }}
                        width={32}
                        height={32} 
                    />
                </Grid>
            </Grid>
        </Container>
    );
}

export default LoadingSkeleton;
