import PlaylistItem from 'components/SongListItem';
import { IPendingTrack } from 'interfaces';

interface IPendingListProps {
    createMusicQueue?: IPendingTrack[]
}

function PendingList(props: IPendingListProps) {
    const {
        createMusicQueue = []
    } = props;

    if (!Array.isArray(createMusicQueue) || !createMusicQueue.length) {
        return null;
    }

    return (
        <ul className="pending-list">
            {
                createMusicQueue.map((track, index) => (
                    <li 
                        key={track.musicId}
                        className="pending-list__item"
                    >
                        <PlaylistItem 
                            idx={index}
                            {...track}
                            disabled
                            asList
                        />
                    </li>
                ))
            }
        </ul>
    );
}

export default PendingList;
