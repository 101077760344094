import React, { useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import { IPlanObject } from 'interfaces';
import Transition from './Transition';

import './SlideDialog.scss';

interface ISlideDialogProps {
    className?: string;
    containerProps?: IPlanObject;
    currentTrack?: IPlanObject;
    open?: boolean;
    // eslint-disable-next-line no-unused-vars
    onClose?: (arg: IPlanObject)=> any;
    fullScreen?: boolean;
    sidebar?: boolean;
    title?: string;
    transitionComponent?: React.ReactElement | any;
    disable?: boolean;
    children: React.ReactNode;
    [key: string]: any;

}

function SlideDialog(props: ISlideDialogProps) {
    const {
        className = '',
        containerProps = {},
        open = false,
        onClose = ()=> {},
        fullScreen = false,
        children,
        title = '',
        transitionComponent = Transition,
        disable = false,
        ...restProps
    } = props;

    const dialogRef: any = useRef();

    const handleClose = ()=>{
        onClose({ isOpen: false });
    };

    const slideTransitionComponent = !disable 
        ? transitionComponent
        : undefined;

    const classNames = `slide-dialog ${disable ? 'slide-dialog--disable' : ''} ${className}`.trim();
    
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullScreen={fullScreen}
            className={classNames}
            ref={dialogRef}
            TransitionComponent={slideTransitionComponent || undefined}
            {...restProps}
        >
            <div 
                className="slide-dialog__wrap"
                {...containerProps}
            >
                <button 
                    className="slide-dialog__btn-close"
                    type='button'
                    onClick={handleClose}
                >
                    <span className='sr-only'>close</span>
                    {disable && (
                        <CloseIcon className='slide-dialog__icon-close' />
                    )}
                </button>
                <div className="slide-dialog__wrap-inner">
                    {title && ( 
                        <DialogTitle className='music-detail-dialog__title'>
                            <span >{title}</span>
                        </DialogTitle>
                    )}
                    <DialogContent className='slide-dialog__content'>
                        {children}
                    </DialogContent>
                </div>
            </div>
        </Dialog>
    );
}

export default SlideDialog;
