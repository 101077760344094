import { useCallback } from 'react';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { ScrollPosition } from 'react-lazy-load-image-component';
import * as tracker from 'utils/tracker';
import { MUSIC_LIKE } from 'constants/trackingEvents';
import ListView from 'components/SongListItem/ListView';
import GalleryView from 'components/SongListItem/GalleryView';
import './SongListItem.scss';
import { IPlanObject } from 'interfaces/index';
import defaultCover from '../../assets/covers/default_cover.jpg';

interface ISongListItemProps {
    idx?: number;
    playlistId?: string;
    isSelected?: boolean;
    showLikeIcon?: boolean;
    disabled?: boolean;
    asList?: boolean;
    scrollPosition?: ScrollPosition;
    // eslint-disable-next-line no-unused-vars
    onMoreClick?: (arg: IPlanObject) => any;
    [key: string]: any;
}

function SongListItem(props: ISongListItemProps) {
    const {
        idx = 0,
        playlistId = '',
        musicId = '',
        musicName = '',
        author = '',
        duration = '',
        liked = false,
        thumbnail = '',
        className = '',
        showLikeIcon = true,
        disabled = false,
        isSelected = false,
        asList = false,
        onClick = ()=>{},
        updateLike = ()=>{},
        scrollPosition,
        onMoreClick = ()=>{},
    } = props;

    const activeCss = isSelected ? 'song-list-item--active' : '';
    const disabledCss = disabled ? 'song-list-item--disabled' : '';
    const galleryViewCss = !asList ? 'song-list-item--gallery' : '';

    const imageSrc = disabled
        ? defaultCover
        : thumbnail;

    const eventTracker = (eventName: string, opt?: IPlanObject | null)=> {
        const data = opt || { musicId };
        tracker.event(eventName, data);
    };

    const handleClick = (e: any)=> {
        if (disabled) {
            return;
        }

        const data = {
            musicId,
            playlistId,
            index: idx,
        };
        // change music callback from parent
        onClick(e, data);
    };

    const handleLikeClick = useCallback((e: any)=> {
        e.preventDefault();
        e.stopPropagation();

        // only send like if not yet liked
        if (disabled || liked) {
            return;
        }

        eventTracker(MUSIC_LIKE);

        updateLike({
            musicId,
            playlistId,
            liked: !liked,
        });
    }, [musicId, playlistId, liked]);

    const handleMoreClick = useCallback((e: any) => {
        e.preventDefault();
        e.stopPropagation();

        const data = {
            musicId,
            playlistId,
            index: idx,
            musicName,
            isOpen: true
        };

        onMoreClick(data);
    },[musicId, playlistId, idx, musicName, onMoreClick]);

    const likeIcon = liked
        ? <FavoriteIcon />
        : <FavoriteBorderIcon />;

    const viewProps = {
        musicName,
        author,
        duration,
        imageSrc,
        showLikeIcon,
        disabled,
        likeIcon,
        liked,
        handleLikeClick,
        scrollPosition,
        handleMore: handleMoreClick,
    };

    const classNames = `song-list-item ${galleryViewCss} ${className} ${activeCss} ${disabledCss}`.trim();
    
    return (
        <button 
            type='button'
            className={classNames}
            onClick={handleClick}
        >
            {asList && (
                <ListView {...viewProps} />
            )}
            {!asList && (
                <GalleryView {...viewProps} />
            )}
        </button>
    );
}

export default SongListItem;
