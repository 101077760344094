import { connect } from 'react-redux';
import { updateView, } from 'actions/music';
import AudioControl from 'components/MiniPlayer/AudioControl';

import { IRootState } from 'interfaces/index';

const mapStateToProps = (state: IRootState) => ({ 
    playlistId: state.music?.currentMusic?.playlistId,
    musicId: state.music?.currentMusic?.musicId,
});

const mapDispatchToProps = (dispatch: any) => ({ updateView: (data: any) => dispatch(updateView(data)), });

export default  connect(mapStateToProps, mapDispatchToProps)(AudioControl);
