import React from 'react';
import { Container } from '@mui/material';
import Grid from '@mui/material/Grid2';
import MusicPlayerControls from 'containers/MusicPlayerControls';
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import LikeButton from 'containers/LikeButton';
import ShareButton from 'containers/ShareButton';
import ProgressBar from 'components/ProgressBar';
import { isDesktop } from 'utils/index';
import VolumeControl from './VolumeControl';

interface IMobileLayout {
    className?: string;
    cover: string;
    musicName: string;
    author: string;
    views: string | number;
    audioRef: React.RefObject<any>;
    progressBarRef: React.RefObject<any>;
    isModalOpen: boolean;
    handleModalOpen: ()=> any
}

function MobileLayout(props: IMobileLayout) {
    const {
        className = '',
        cover = '',
        musicName = '',
        author = '',
        views = '',
        audioRef,
        progressBarRef,
        isModalOpen = false,
        handleModalOpen = ()=>{},
    } = props;

    const classNames = `mobile-layout ${className}`.trim();

    return (
        <div 
            className={classNames}
            onClick={handleModalOpen}
            onKeyDown={handleModalOpen}
            role="button"
            tabIndex={0}
            hidden={isModalOpen}
        >
            <Container maxWidth="lg">
                <Grid container>
                    <Grid 
                        size={{ xs: 8 }}
                        className="miniPlayer__col-details" 
                    >
                        <div 
                            className="miniPlayer__music-img" 
                            style={{ backgroundImage: `url(${cover})` }}
                        />
                        <div className="miniPlayer__description">
                            <h5 className="text-truncated">
                                {musicName}
                            </h5>
                            <small className="text-truncated">
                                {author}
                            </small>
                            <div className="miniPlayer__listened">
                                <MusicNoteIcon />
                                {views}
                            </div>
                        </div>
                    </Grid>
                    <Grid 
                        className="miniPlayer__controls"
                        size={{ xs: 4 }}
                    >   
                        {/* @ts-ignore */}
                        <LikeButton className="miniPlayer__like" />
                        <ShareButton className="miniPlayer__icon-share" />
                        <MusicPlayerControls showFullControls={isDesktop} />
                        <VolumeControl 
                            audioRef={audioRef}
                            showUi={false} 
                        />
                    </Grid>
                </Grid>
            </Container>
            <ProgressBar progressBarRef={progressBarRef} />
        </div>
    );
}

export default MobileLayout;
