import { connect } from 'react-redux';
import ShareBar from 'components/ShareBar';

import { IRootState } from 'interfaces/index';

const mapStateToProps = (state: IRootState) => ({ currentMusic: state.music.currentMusic });

const mapDispatchToProps = () => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);
// @ts-ignore
export default connector(ShareBar);
