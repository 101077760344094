import { 
    useEffect,
    useState,
} from 'react';
import {
    Formik, 
    Form,
} from 'formik';
import ErrorMessage from 'components/ErrorMessage';
import {
    addToPlaylistSchema,
    addToPlayInitValues
} from 'validationSchemas/addToPlaylist';
import Button from '@mui/material/Button';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Divider from '@mui/material/Divider';
import MusicItem from 'components/MusicItem';
import {
    ERROR,
    SUBMITTING,
    OPERATIONS,
} from 'constants/index';
import { IPlanObject } from 'interfaces';

interface IUpdatePlaylistFormProps {
    status: string;
    operation?: string;
    buttonText?: string;
    musicDeatils: IPlanObject;
    playlists: IPlanObject[];
    playlistId: string;
    // eslint-disable-next-line no-unused-vars
    handleFormSubmit: (arg:any)=> any;
    handleCancel: ()=> any;
}

/**
 * UpdatePlaylistForm
 * @description 
 * form for manage a song in a playlist.
 * a song can not add again into existing playlist
 * a song can only remove from current playlist 
 * @param {Object} props 
 * @returns {Object} react element
 */
function UpdatePlaylistForm(props: IUpdatePlaylistFormProps) {
    const {
        status = '',
        operation = OPERATIONS.ADD, // Add | remove
        buttonText = 'OK',
        musicDeatils, // current song details
        playlists = [], // user's playlist ids
        playlistId = '', // current playlist Id
        handleFormSubmit = ()=>{},
        handleCancel = ()=>{},
    } = props;

    const [initFormValue, setInitFormValue] = useState({ ...addToPlayInitValues } as IPlanObject);

    // onMount update form init values: playlistId if provided
    // eg. when user remove a song from current playlist
    useEffect(()=> {
        if (playlistId) {
            setInitFormValue({
                ...initFormValue,
                playlistId,
            });
        }
    }, []);

    const isSubmitting = status === SUBMITTING;

    const ctaButtonText = isSubmitting
        ? 'Saving...'
        : buttonText;


    return (
        <div className="addToPlaylist-form">
            <Formik 
                initialValues={initFormValue}
                validationSchema={addToPlaylistSchema}
                onSubmit={handleFormSubmit}
                enableReinitialize
            >
                {({
                    values,
                    setFieldValue
                }) => (
                    <Form autoComplete="off">
                        { status === ERROR && (
                            <ErrorMessage />
                        )}
                            
                        {musicDeatils && (
                            <MusicItem
                                className='addToPlaylist-form__music-item' 
                                musicName={musicDeatils.musicName}
                                author={musicDeatils.author}
                                thumbnail={musicDeatils.thumbnail}
                            />
                        )}
                        <Divider />
                        <FormControl className='addToPlaylist-form__playlists'>
                            <RadioGroup
                                aria-labelledby="add-to-playlist-radio-buttons-group"
                                name="playlistId"
                                value={values.playlistId}
                                onChange={(e: any)=> setFieldValue('playlistId', e.target.value)}
                            >
                                {
                                    playlists.map((playlist = {}) => {
                                        const {
                                            id = '',
                                            title = '',
                                        } = playlist;

                                        // don't render song in current playlist for add
                                        if (operation === OPERATIONS.ADD && id === playlistId) {
                                            return null;
                                        }
                                        // only render song in current playlist for remove
                                        if (operation === OPERATIONS.REMOVE && id !== playlistId) {
                                            return null;
                                        }

                                        return (
                                            <FormControlLabel 
                                                key={id}
                                                value={id}
                                                control={<Radio />}
                                                label={title}
                                            />
                                        );
                                    })
                                }
                            </RadioGroup>
                        </FormControl>
                        <div className="button-section">
                            <Button 
                                color="primary"
                                className="submit-button"
                                variant="contained"
                                type="submit"
                                disabled={isSubmitting}
                                size="large"
                            >
                                {ctaButtonText}
                            </Button>
                            <Button 
                                color="primary"
                                className="cancel-button"
                                variant="outlined"
                                type="reset"
                                size="large"
                                disabled={isSubmitting}
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
}

export default UpdatePlaylistForm;
