import { connect } from 'react-redux';
import { signOutUser } from 'actions/account';
import DrawerMenu from 'components/DrawerMenu';

import { IRootState } from 'interfaces/index';

const mapStateToProps = (state: IRootState) => ({ isAuthenticated: !!state.account?.authenticated });

const mapDispatchToProps = (dispatch: any) => ({ signOutUser: () => dispatch(signOutUser()) });

const connector = connect(mapStateToProps, mapDispatchToProps);
// @ts-ignore
export default connector(DrawerMenu);
