/* eslint-disable import/no-extraneous-dependencies */
import {
    applyMiddleware,
    compose,
    legacy_createStore as createStore,
} from 'redux';
// import { composeWithDevTools } from '@redux-devtools/extension';
import { thunk } from 'redux-thunk';
import rootReducer from 'reducers';
import { RESET_APP } from 'constants/actionTypes';

declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window {
      __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

/*
// store structure
account: {
    user: {}
},
music: {
    currentMusic: {
        id: string
        playlistId: string,
        index: number,
        isPlaying: boolean
    },
    playlists: {
        id: {
            status: string,
            data: []
        },
        id: {
            status: string,
            data: []
        },
    }
},
creation: {
    artists: {
        status: string,
        rappers: [],
        singers: []
    },
    musicSelections: {
        status: string,
    },
    playlist: {
        status: string,
    }
}
*/

export function getInitState() {
    return {
        music: {
            currentMusic: { isPlayerOpen: false },
            playlists: {},
            createMusicQueue: [],
            slideDialog: {},
        },
        creation: {},
        account: {},
        [RESET_APP]: false,
    };
}

function configureStore(preloadedState = {}) {
    const middlewares = [thunk];

    if (process.env.NODE_ENV === 'development') {
        const composedEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

        return createStore(
            rootReducer,
            preloadedState,
            composedEnhancers(applyMiddleware(...middlewares)),
        );
    }

    return createStore(
        rootReducer,
        preloadedState,
        applyMiddleware(...middlewares),
    );
}

export const store = configureStore(getInitState());
