
interface IEmoji {
    className?: string;
    ariaLabel?: string;
    symbol: string;
}

function Emoji(props: IEmoji) {
    const { 
        className = '', 
        ariaLabel = 'hidden', 
        symbol = '', 
    } = props;

    return (
        <span 
            className={className} 
            role="img" 
            aria-label={ariaLabel}
        >{/*
            // @ts-ignore */}
            {String.fromCodePoint(symbol)}
        </span>
    );
}

export default Emoji;
