import React, { 
    useState, 
    useEffect,
} from 'react';

import IconButton from '@mui/material/IconButton';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';

export interface IVolumeControlProps {
    className?: string;
    audioRef: React.RefObject<any>;
    showUi?: boolean;
}

function VolumeControl(props: IVolumeControlProps) {
    const {
        className = '',
        audioRef,
        showUi = true,
    } = props;

    const [volume, setVolume] = useState(60);
    const [muteVolume, setMuteVolume] = useState(false);

    useEffect(() => {
        if (audioRef && audioRef.current) {
            audioRef.current.volume = volume / 100;
            audioRef.current.muted = muteVolume;
        }
    }, [volume, audioRef, muteVolume]);

    let VolumeIcon = VolumeUpIcon;

    if (volume < 40) {
        VolumeIcon = VolumeDownIcon;
    }

    if (muteVolume || volume < 5) {
        VolumeIcon = VolumeOffIcon;
    }

    if (!showUi) {
        return null;
    }

    const classNames = `volume-control ${className}`.trim();

    return (
        <div className={classNames}>
            <IconButton 
                className="volume-control__button"
                onClick={() => setMuteVolume((prev) => !prev)}
                type='button'
                aria-label="Volume"
            >
                <VolumeIcon />
            </IconButton>
            <input
                type="range"
                min={0}
                max={100}
                value={volume}
                onChange={(e) => setVolume(Number(e.target.value))}
                style={{ background: `linear-gradient(to right, #ADD038 ${volume}%, #ccc ${volume}%)` }}
                className="volume-control__input"
            />
        </div>
    );
}

export default VolumeControl;
