import Grid from '@mui/material/Grid2';
import { Container } from '@mui/material';
import MusicPlayerControls from 'containers/MusicPlayerControls';
import ProgressInputRange from 'components/MusicPlayer/ProgressInputRange';
import LyricsPanel from 'components/MusicPlayer/LyricsPanel';
import PromptPanel from 'components/PromptPanel';
import { isDesktop } from 'utils';

import './MusicDetails.scss';

import { IPlanObject } from 'interfaces';

interface IMusicDetailsProps {
    className?: string;
    currentTrack: IPlanObject;
    autoPlayAllPlaylists: boolean;
    [key: string]: any;
}

function MusicDetails(props: IMusicDetailsProps) {
    const {
        className = '',
        currentTrack = {},
        playlistTitle = '',
        duration = 0,
        audioRef,
        progressInputRangeRef,
        progressTimeRef,
        handleNext = ()=> {},
        handlePrevious = ()=> {},
        handleRepeat = ()=>{},
        handlesRepeatSingle = ()=>{},
        handleShuffle = ()=>{},
        autoPlayAllPlaylists = false,
    } = props;

    const {
        cover = '',
        musicName = '',
        author = '',
        lyrics = '',
        context = {},
    } = currentTrack;

    const classsNames = `music-details ${className} ${isDesktop ? 'desktop' : ''}`.trim();

    return (
        <div className={classsNames}>
            <div 
                className="music-details__music-img" 
                style={{ backgroundImage: `url(${cover})` }}
            />
            <h3 className="bold">{musicName}</h3>
            <small>{author}</small>
            <small className='text-xs'>Playlist: {playlistTitle}</small>
            <Container maxWidth="sm">
                <ProgressInputRange {...{
                    className: 'mt-1',
                    progressInputRangeRef,
                    progressTimeRef,
                    audioRef,
                    duration
                }} />
            
                <MusicPlayerControls 
                    {...{
                        audioRef,
                        handleNext,
                        handlePrevious,
                        showVolumn: false,
                        autoPlayAllPlaylists,
                        handleRepeat,
                        handlesRepeatSingle,
                        handleShuffle,
                    }}
                />
            </Container>
            <Grid 
                container
                spacing={3}
                className="music-details__container"
                maxWidth="sm"
            >
                <Grid size={{ xs:12 }}>
                    <PromptPanel 
                        className="music-details__prompt"
                        musicContext={context} 
                    />
                </Grid>

                <Grid size={{ xs:12 }}>
                    <LyricsPanel 
                        className="music-player__lyrics-panel"
                        lyricsUrl={lyrics}
                        title="Lyrics"
                    />
                </Grid>
            </Grid>
        </div>
    );
}

export default MusicDetails;
