export const MUSIC_LIKE = 'music_like';

export const MUSIC_PLAY = 'music_play';

export const MUSIC_PAUSE = 'music_pause';

export const MUSIC_NEXT = 'music_next';

export const MUSIC_PREVIOUS = 'music_previous';

export const MUSIC_FORWARD = 'music_forward';

export const MUSIC_BACKWARD = 'music_backward';

export const MOBILE_SHARE = 'mobile_share';

export const DESKTOP_SHARE = 'desktop_share';

export const CREATE = 'create';