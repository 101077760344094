import Button, { ButtonProps } from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import * as tracker from 'utils/tracker';
import { MOBILE_SHARE } from 'constants/trackingEvents';
import { shareMusic } from 'utils';

import './ShareButton.scss';
import { ICurrentMusic } from 'interfaces';

export interface IShareButton extends ButtonProps {
    currentMusic: ICurrentMusic;
    [key: string]: any
}

function ShareButton(props: IShareButton) {
    const {
        className = 'share-button',
        currentMusic = {} as ICurrentMusic,
        variant = 'outlined',
        size = 'large',
        endIcon,
        onClick = ()=>{},
        asTextButton = false,
        stopPropagation = true,
        ...restProps
    } = props;

    const mergedProps = {
        ...restProps,
        className,
        variant,
        size,
    };


    const Icon = endIcon || <ShareOutlinedIcon />;

    const handleClick = async (e: any)=> {
        try {
            if (stopPropagation) {
                e.stopPropagation();
            }
            const sharedOptions = await shareMusic(currentMusic);

            if (sharedOptions) {
                tracker.event(MOBILE_SHARE, { shareUrl: sharedOptions.url || '' });
            }

            e.shareData = sharedOptions;
            onClick(e);
        } catch(err) {
            // console.error(err);
        }
    };

    if (!asTextButton) {
        return (
            <IconButton
                className='share-button__icon-button' 
                color="inherit"
                onClick={handleClick}
            >
                <ShareOutlinedIcon />
            </IconButton>
        );
    }

    return (
        <Button 
            {...mergedProps}
            endIcon={Icon}
            onClick={handleClick}
        >
            Share
        </Button>
    );
}

export default ShareButton;
