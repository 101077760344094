import { Link } from 'react-router-dom';
import {
    PRIVACY_POLICY,
    TERMS_CONDITIONS,
} from 'constants/routes';

import './Footer.scss';

interface IFooterProps {
    className?: string;
    asColumn?: boolean;
}

function Footer(props: IFooterProps) {
    const {
        className = '',
        asColumn = false,
    } = props;

    const classNames = `footer ${className}`.trim();

    const date = new Date();

    const lineBreak = asColumn
        ? <br />
        : ' ';

    return (
        <footer className={classNames}>
            <small className="text-center">
                <p>
                    Copyright © {date.getFullYear()} Songx.
                    {lineBreak}
                    All rights reserved.
                </p>
                {!asColumn && (
                    <p>
                        <Link to={PRIVACY_POLICY}>Privacy policy</Link> | <Link to={TERMS_CONDITIONS}>Terms and conditions</Link>
                    </p>
                )}
                {asColumn && (
                    <>
                        <p>
                            <Link to={PRIVACY_POLICY}>Privacy policy</Link>
                        </p>
                        <p>
                            <Link to={TERMS_CONDITIONS}>Terms and conditions</Link>
                        </p>
                    </>
                )}
            </small>
        </footer>
    );
}

export default Footer;

