
import './PlaylistCover.scss';

interface IPlaylistCoverProps {
    className?: string;
    title: string;
    cover?: string;
    // eslint-disable-next-line no-unused-vars
    onClick?: (event: any)=>any;
}

function PlaylistCover(props: IPlaylistCoverProps) {
    const {
        className = '',
        title = '',
        cover = '',
        onClick = ()=>{},
    } = props;

    const handleClick = (e: any)=>{
        onClick(e);
    };

    const coveStyle = cover
        ? { backgroundImage: `url(${cover})` }
        : undefined;

    const classNames = coveStyle
        ? `playlist-cover cover-art ${className}`.trim()
        : `playlist-cover ${className}`.trim();

    return (
        <button 
            className={classNames}
            style={coveStyle}
            type="button"
            onClick={handleClick}
        >
            <small className="playlist-cover__title text-truncated">
                {title}
            </small>
        </button>
    );
}

export default PlaylistCover;
