import { 
    useRef,
    useState
} from 'react';
import IconButton from '@mui/material/IconButton';
import LinkIcon from '@mui/icons-material/Link';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Emoji from 'components/Emoji';
import { copyTextToClipboard } from 'utils/index';

import './ShareLink.scss';

interface IShareLinkProps {
    className?: string;
    shareUrl?: string;
    onclick?: ()=> any;
}

function ShareLink(props: IShareLinkProps) {
    const {
        className = '',
        shareUrl = '',
        onclick = () => {},
    } = props;

    const [open, setOpen] = useState(false);

    const inputRef: any = useRef(null);
    
    const handleClose = () => {
        setOpen(false);
    };

    const handleCopyLink = async (e: any)=>{
        e.preventDefault();
        try {
            inputRef.current.value = shareUrl; 
            inputRef.current.select();
            await copyTextToClipboard(shareUrl);
            onclick();
            setOpen(true);
        } catch(err) {
            // console.log('error copy link: ', err);
        }
    };

    const classNames = `share-link ${className}`.trim();

    return (
        <div className={classNames}>
            <IconButton 
                className="share-link__btn"
                onClick={handleCopyLink}
            >
                <LinkIcon 
                    className='share-link__icon'
                    fontSize="large"
                />
            </IconButton>
            <input 
                className="share-link__input"
                type='text'
                value={shareUrl}
                ref={inputRef}
                readOnly
            />

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    URL copied!
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        You can paste the URL to your Facebook, Twitter, Email or Chats and get feedbacks from your friends <Emoji symbol="0x1F60A" />
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button 
                        onClick={handleClose}
                        autoFocus
                    >
                        OK
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ShareLink;
