import {
    toNumber, 
    toStanceCase,
    formatTime,
} from 'utils/formatters';
import { SUCCESS } from 'constants/index';
import {
    IPlanObject, 
    IPlaylistData, 
    ITrack 
} from 'interfaces';

import { MIX_MUSIC_STORAGE_PATH } from 'constants/api';

import defaultCover from '../assets/covers/default_cover.jpg';

function convertNaString(info: string) {
    const infoString = typeof info === 'string'
        ? info.trim()
        : '';

    return infoString !== 'na'
        ? infoString
        : '';
}

// eslint-disable-next-line no-unused-vars
export function convertMusicData(music = {} as ITrack, index = 0) {
    // fix API naming convention
    return {
        musicId: music.musicId || music.songId || '',
        musicName: music.musicName || music.title || '',
        src: music.fileLocation || '',
        lyrics: music.lyricsFileLocation || '',
        thumbnail: music.imageThumbnailDownloadUrl || music.imageDownloadUrl || defaultCover,
        cover: music.imageDownloadUrl || music.imageThumbnailDownloadUrl || defaultCover,
        author: music.creatorNickname || music.createdBy || '',
        email: music.email || '',
        duration: music.duration || '',
        liked: music.liked || false,
        likes: toNumber(music.likes),
        views: toNumber(music.views),
        // email: music.email || '',
        archieve: music.archieve || false,
        createdDateTime: music.createdDateTime || '',
        lastChangedDateTime: music.lastChangedDateTime || '',
        context: music.context ? {
            singerId: convertNaString(music.context.singerId),
            rapperId: convertNaString(music.context.rapperId),
            mixMusicId: convertNaString(music.context.mixmusic_id),
            prompt: `${music.context.context_prompt || ''}`.trim(),
            version: music.context.version || '1.0'
        }: {},
    };
}

export function convertPlaylistData(data: IPlaylistData) {
    const ret = {
        id: data.id || '',
        createdBy: data.createdBy || '',
        profileId: data.createdBy_profile_id ||'',
        title: data.title || '',
        private: !!data.private,
        archieve: !!data.archieve,
        musics: data.musics,
        status: SUCCESS
    };

    ret.musics = data.musics && Array.isArray(data.musics)
        ? ret.musics.map(convertMusicData)
        : [];

    return ret;
}

export function convertArtistData(data = {} as IPlanObject) {
    return {
        id: data.artist_id || '',
        description: data.descriptions || '',
        voice: data.artist_voice || '',
        gender: data.gender || '',
    };
}
// TODO - convert to array of IArtist
export function convertArtistsData(artisData = {} as IPlanObject) {
    const rappersData = Array.isArray(artisData.rap_artists)
        ? artisData.rap_artists.map(convertArtistData)
        : [];

    const singersData = Array.isArray(artisData.singer_artists)
        ? artisData.singer_artists.map(convertArtistData)
        : [];

    return {
        rappers: rappersData,
        singers: singersData,
    };
}

export function convertMusicSelectionData(data = {} as IPlanObject) {
    const musics: IPlanObject = data.music || {};
    const musicsData = [];
    const moods: IPlanObject[] = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const [key = '', value = {} as IPlanObject] of Object.entries(musics)) {
        // build moods data
        if (value.mood && value.mood_id) {
            const existMood = moods.find((item)=>(item.id === value.mood_id));
            if (!existMood) {
                moods.push({
                    id: value.mood_id || '',
                    label: toStanceCase(value.mood)
                });
            }
        }

        // build data
        musicsData.push({
            id: `${value.id}`,
            fileName: key,
            filePath: `${MIX_MUSIC_STORAGE_PATH}${key}.mp3`,
            mood: toStanceCase(value.mood || ''),
            moodId: value.mood_id || '',
            bpm: `${value.bpm || ''}`,
            length: formatTime(value.playLength),
        });
    }

    return {
        data: musicsData,
        moods
    };
}

// map form values to match API signature
export function convertCreateMusicRequest(data = {} as any) {
    const {
        creatorNickname = '',
        musicName = '',
        musicContext = '',
        contactEmail = '',
        artistId = '',
        mixmusicId = '',
        version = '2.0',
    } = data;

    return {
        creatorNickname,
        musicName,
        musicContext,
        contactEmail,
        artist_id: artistId,
        mixmusic_id: mixmusicId,
        version,
    };
}

export function convertCreateMusicReponse(data = {} as IPlanObject) {
    return {
        ...data,
        musicId: data.musicId || data.song_id || data.id || '',
        author: data.creatorNickname || ''
    };
}

export function convertUserData(songxUserData = {} as IPlanObject, providerUserData = {} as IPlanObject) {
    const providerMetaData = providerUserData.metadata || {};

    return {
        user: {
            id: songxUserData.id || '',
            email: songxUserData.email || '',
            displayName: songxUserData.displayName || providerUserData.displayName || '',
            firstName: songxUserData.firstname || providerUserData.firstName || '',
            lastName: songxUserData.lastname || providerUserData.lastName || '',
            bio: songxUserData.bios || '',
            dob: songxUserData.dob || '',
            photoUrl: songxUserData.profilePhoto || providerUserData.photoURL || '',
            customPhoto: songxUserData.customPhoto || '',
            playlists: songxUserData.playlists || [],
            createdDateTime: songxUserData.createdDateTime || '',
            lastChangedDateTime: songxUserData.lastChangedDateTime || '',
            providerData: {
                ...providerMetaData,
                uid: providerUserData.uid,
                emailVerified: providerUserData.emailVerified,
                isAnonymous: !!providerUserData.isAnonymous,
            }
        },
    };
}

export function convertCreatePlaylistData(opt = {} as IPlanObject) {
    return { 
        playlistName: opt.playlistName,
        private: !!opt.private
    };
}

export function convertUpdatePlaylistData(values = {} as IPlanObject) {
    const ret = { private: !!values.private, } as IPlanObject;

    if (values.playlistName) {
        ret.title = values.playlistName;
    }

    if (typeof values.archieve === 'boolean') {
        ret.archieve = values.archieve;
    }

    if (Array.isArray(values.musicIds)) {
        ret.musicIds = values.musicIds;
    }

    return ret;
}

export function convertUserProfileUpdate(data = {} as IPlanObject) {
    return {
        firstname: data.firstName || '',
        lastname:  data.lastName || '',
        dob: '',
        displayName: data.displayName || '',
        bios:  data.bio || '',
    };
}

export function convertUpdatedProfileData(data = {} as IPlanObject) {
    return {
        id: data.id || '',
        firstName: data.firstname || '',
        lastName:  data.lastname || '',
        dob: '',
        displayName: data.displayName || '',
        bios:  data.bio || '',
        photoUrl: data.profilePhoto || '',
        customPhoto: data.customPhoto || '',
        createdDateTime: data.createdDateTime || '',
        lastChangedDateTime: data.lastChangedDateTime || ''
    };
}

export function convertUserPlaylistsData(data = [] as IPlanObject[]) {
    const ret = {} as IPlanObject;

    data.forEach((playlist = {} as IPlanObject)=>{
        if (playlist.id) {
            ret[playlist.id] = {
                id: playlist.id || '',
                title: playlist.title || '',
                private: !!playlist.private,
                archieve: !!playlist.archieve,
                cover: playlist.cover || ''
            };
        }
    });

    return ret;
}
