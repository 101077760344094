/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import PersonIcon from '@mui/icons-material/Person';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import {
    MY_SONGX,
    PROFILE,
    REWARDS
} from 'constants/routes';

export const drawerMenu = [
    {
        title: 'My Songx',
        url: MY_SONGX,
        icon: LibraryMusicIcon
    },
    {
        title: 'Profile',
        url: PROFILE,
        icon: PersonIcon
    },
    /*
    {
        title: 'Rewards',
        url: REWARDS,
        icon: CardGiftcardIcon
    }
    */
];
