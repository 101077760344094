import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function ScrollToTop() {
    const {
        pathname,
        state,
    } = useLocation();

    useEffect(() => {
        // don't scroll to top eg. on home screen
        // console.log('ScrollToTop state: ', state);
        const isScroll = state && typeof state.scrollTop === 'boolean'
            ? state.scrollTop
            : true;

        // console.log('isScroll: ', isScroll);
        if (!isScroll) {
            return;
        }
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

export default ScrollToTop;
