import { connect } from 'react-redux';
import {
    updateLike,
    setSlideDialogOpen
} from 'actions/music';
import SongList from 'components/SongLlist';

import {
    IPlanObject, IRootState 
} from 'interfaces/index';

const mapStateToProps = (state: IRootState) => ({ 
    currentMusicId: state.music.currentMusic?.musicId || '',
    currentPlaylistId: state.music.currentMusic?.playlistId || '',
    createMusicQueue: state.music.createMusicQueue,
    createMusicStatus: state.music.createMusicStatus,
    isAuthenticated: !!state.account?.authenticated,
});

const mapDispatchToProps = (dispatch: any) => ({ 
    updateLike: (data: any) => dispatch(updateLike(data)),
    setSlideDialogOpen: (data: IPlanObject) => dispatch(setSlideDialogOpen(data)),
});

const connector = connect(mapStateToProps, mapDispatchToProps);
// @ts-ignore
export default connector(SongList);
