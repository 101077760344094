import './MusicItem.scss';

interface IMusicItemProps {
    className?: string;
    musicName?: string;
    author?: string;
    thumbnail?: string;
}

function MusicItem(props: IMusicItemProps) {
    const {
        className = '',
        musicName = '',
        author = '',
        thumbnail = '',
    } = props;

    const coveStyle = thumbnail
        ? { backgroundImage: `url(${thumbnail})` }
        : undefined;

    const classNames = `music-item ${className}`.trim();

    return (
        <div className={classNames}>
            <div 
                className="music-item__img"
                style={coveStyle} 
            />
            <div className="music-item__description">
                <h4 className="text-truncated">
                    {musicName}
                </h4>
                <small className="text-truncated">
                    {author}
                </small>
            </div>
        </div>
    );
}

export default MusicItem;
