import { IconButton, } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

interface IMoreButtonProps {
    className?: string;
    // eslint-disable-next-line no-unused-vars
    onClick?: (ar: any)=>any;
    [key: string]: any;
}

function MoreButton(props: IMoreButtonProps) {
    const {
        className = '',
        onClick = ()=>{},
        sx = { padding: 0 },
        ...rest
    } = props;

    const classNames = `more-btn ${className}`.trim();

    return (
        <IconButton
            className={classNames} 
            color="inherit"
            href="#"
            sx={sx}
            onClick={onClick}
            {...rest}
        >
            <MoreVertIcon titleAccess="more" />
        </IconButton>
    );
}

export default MoreButton;
