export const HOME = '/';
export const PLAYLIST_ID_MUSIC_ID = '/:playlistId/:musicId';
export const PLAYLIST_BY_ID = '/playlist/:playlistId';
export const CREATE = '/create';
export const STEP_PREFIX = 'step-';
export const SIGN_IN = '/sign-in';
export const MY_SONGX = '/my-songx';
export const PROFILE = `${MY_SONGX}/profile`;
export const REWARDS = `${MY_SONGX}/rewards`;
export const PRIVACY_POLICY = `/privacy-policy`;
export const TERMS_CONDITIONS = `/terms-and-conditions`;