import { connect } from 'react-redux';
import { updateLike } from 'actions/music';
import LikeButton from 'components/LikeButton';

import { IRootState } from 'interfaces/index';

const mapStateToProps = (state: IRootState) => (state.music);

const mapDispatchToProps = (dispatch: any) => ({ updateLike: (data: any) => dispatch(updateLike(data)) });

// @ts-ignore
export default  connect(mapStateToProps, mapDispatchToProps)(LikeButton);
