import Skeleton from '@mui/material/Skeleton';
import { IPlanObject } from 'interfaces';

interface ILoadingSkeletonProps {
    numItems?: number
    className?: string
    skeletonProps?: IPlanObject
}

function LoadingSkeleton(props: ILoadingSkeletonProps) {
    const {
        numItems = 10,
        className = 'top-songs-skelton',
        skeletonProps
    } = props;

    const mockItems: any[] = [...Array(numItems)];

    const itemClassName = `${className}__item`;

    return (
        <>
            {
                mockItems.map((item, index) => (
                    <li 
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${className}-${index}`}
                        className='top-songs-skelton'
                    >
                        <Skeleton 
                            className={itemClassName}
                            variant="rectangular"
                            {...skeletonProps} 
                        />
                    </li>)
                )
            }
        </>
    );
}

export default LoadingSkeleton;
