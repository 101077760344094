import {
    Navigate,
    Outlet,
    useLocation,
} from 'react-router-dom';
import { SIGN_IN } from 'constants/routes';


import {
    IPlanObject, 
    IAccountState
} from 'interfaces';

interface IProtectedRoutePros {
    account?: IPlanObject | IAccountState;
    redirectPath?: string;
    children?: any
};


function ProtectedRoute(props: IProtectedRoutePros) {
    const { 
        account = {}, 
        redirectPath = SIGN_IN,
        children
    } = props;

    const location = useLocation();

    if (!account.authenticated) {
        return (
            <Navigate 
                to={redirectPath}
                replace 
                state={{ req: location }}
            />
        );
    }
    
    return children || <Outlet />;
};

export default ProtectedRoute;
  