import { connect } from 'react-redux';
import ShareButton from 'components/ShareButton';

import { IRootState } from 'interfaces/index';

const mapStateToProps = (state: IRootState) => ({ currentMusic: state.music.currentMusic });

const mapDispatchToProps = () => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);
// @ts-ignore
export default connector(ShareButton);
