import { connect } from 'react-redux';
import {
    getSongxUserProfile,
    signOutUser
} from 'actions/account';
import App from 'components/App';

import {
    IPlanObject,
    IRootState
} from 'interfaces/index';

const mapStateToProps = (state: IRootState) => ({ user: state.account?.user });

const mapDispatchToProps = (dispatch: any) => ({ 
    getSongxUserProfile: (data: IPlanObject) => dispatch(getSongxUserProfile(data)),
    signOutUser: ()=> dispatch(signOutUser)
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(App);
