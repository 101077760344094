export const RESET_APP = 'RESET_APP';

export const LOGIN_PENDING = 'LOGIN_PENDING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOFF_SUCCESS = 'LOGOFF_SUCCESS';
export const LOGOFF_ERROR = 'LOGOFF_ERROR';

export const LOADING_MUSIC = 'LOADING_MUSIC';
export const LOAD_MUSIC_SUCCESS = 'LOAD_MUSIC_SUCCESS';
export const LOAD_MUSIC_ERROR = 'LOAD_MUSIC_ERROR';

export const LOADING_ARTISTS = 'LOADING_ARTISTS';
export const LOADING_ARTISTS_SUCCESS = 'LOADING_ARTISTS_SUCCESS';
export const LOADING_ARTISTS_ERROR = 'LOADING_ARTISTS_ERROR';

export const LOADING_MUSIC_SELECTION = 'LOADING_MUSIC_SELECTION';
export const LOADING_MUSIC_SELECTION_SUCCESS = 'LOADING_MUSIC_SELECTION_SUCCESS';
export const LOADING_MUSIC_SELECTION_ERROR = 'LOADING_MUSIC_SELECTION_ERROR';

export const CONVERTING_MIX_MUSIC_URL = 'CONVERTING_MIX_MUSIC_URL';
export const CONVERT_MIX_MUSIC_URL_SUCCESS = 'CONVERT_MIX_MUSIC_URL_SUCCESS';
export const CONVERT_MIX_MUSIC_URL_ERROR = 'CONVERT_MIX_MUSIC_URL_ERROR';

export const LOADING_PLAYLIST = 'LOADING_PLAYLIST';
export const LOAD_PLAYLIST_SUCCESS = 'LOAD_PLAYLIST_SUCCESS';
export const LOAD_PLAYLIST_ERROR = 'LOAD_PLAYLIST_ERROR';

export const UPDATE_CURRENT_MUSIC_INDEX = 'UPDATE_CURRENT_MUSIC_INDEX';
export const UPDATE_PLAYER_STATUS = 'UPDATE_PLAYER_STATUS';
export const UPDATE_LIKE = 'UPDATE_LIKE';
export const UPDATE_VIEW = 'UPDATE_VIEW';

export const CREATING_MUSIC = 'CREATING_MUSIC';
export const CREATE_MUSIC_SUCCESS = 'CREATE_MUSIC_SUCCESS';
export const CREATE_MUSIC_ERROR = 'CREATE_MUSIC_ERROR';
export const UPDATE_CREATE_MUSIC_STATUS = 'UPDATE_CREATE_MUSIC_STATUS';

export const UPDATE_PLAYER_OPEN_STATE = 'UPDATE_PLAYER_OPEN_STATE';
export const UPDATE_SLIDE_DIALOG_OPEN_STATE = 'UPDATE_SLIDE_DIALOG_OPEN_STATE';

export const UPDATING_PROFILE = 'UPDATING_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';

export const CREATING_PLAYLIST = 'CREATING_PLAYLIST';
export const CREATE_PLAYLIST_SUCCESS = 'CREATE_PLAYLIST_SUCCESS';
export const CREATE_PLAYLIST_ERROR = 'CREATE_PLAYLIST_ERROR';
export const RESET_CREATE_PLAYLIST = 'RESET_CREATE_PLAYLIST';
export const DELETING_PLAYLIST = 'DELETING_PLAYLIST';
export const DELETE_PLAYLIST_SUCCESS = 'DELETE_PLAYLIST_SUCCESS';
export const DELETE_PLAYLIST_ERROR = 'DELETE_PLAYLIST_ERROR';
export const UPDATING_PLAYLIST = 'UPDATING_PLAYLIST';
export const UPDATE_PLAYLIST_SUCCESS = 'UPDATE_PLAYLIST_SUCCESS';
export const UPDATE_PLAYLIST_ERROR = 'UPDATE_PLAYLIST_ERROR';

export const UPDATE_REPEAT_PLAYLIST = 'UPDATE_REPEAT_PLAYLIST';
export const UPDATE_REPEAT_SINGLE = 'UPDATE_REPEAT_SINGLE';
export const UPDATE_SHUFFLE_PLAYLIST = 'UPDATE_SHUFFLE_PLAYLIST';

export const LOADING_USERS_PLAYLISTS = 'LOADING_USERS_PLAYLISTS';
export const LOADING_USERS_PLAYLISTS_SUCCESS = 'LOADING_USERS_PLAYLISTS_SUCCESS';
export const LOADING_USERS_PLAYLISTS_ERROR = 'LOADING_USERS_PLAYLISTS_ERROR';

export const LOADING_PLAYLISTS = 'LOADING_PLAYLISTS';
export const LOAD_PLAYLISTS_SUCCESS = 'LOAD_PLAYLISTS_SUCCESS';
export const LOAD_PLAYLISTS_ERROR = 'LOAD_PLAYLISTS_ERROR';