import { ReactComponent as Logo } from 'assets/wisetree_logo.svg';
import './WiseTreeLogo.scss';

interface IWiseTreeLogoProps {
    className?: string;
}

function WiseTreeLogo(props: IWiseTreeLogoProps) {
    const {
        className = '' 
    } = props;

    const classNames = `logo-wiretree-ai ${className}`.trim();

    return (
        <span className={classNames}>
            <span className="sr-only">Songx</span>
            <Logo aria-hidden />
        </span>
    );
}

export default WiseTreeLogo;

