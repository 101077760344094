import { getArtistDetails } from 'utils/musicDetails';
import { IMusicContext } from "interfaces";

import './PromptPanel.scss';

interface IPromptPanelProps {
    musicContext: IMusicContext;
    className?: string;
}

function PromptPanel(props: IPromptPanelProps) {
    const {
        musicContext = {} as IMusicContext,
        className = '',
    } = props;

    const {
        prompt = ''
    } = musicContext;

    if (!prompt) {
        return null;
    }

    const artists = getArtistDetails(musicContext);

    const classNames = `prompt-panel ${className}`.trim();

    return (
        <div className={classNames}>
            <div className="prompt-panel__artists">
                {
                    artists.map((artist)=> (
                        <div 
                            key={artist.imgSrc}
                            className="prompt-panel__artist"
                        >
                            <span 
                                className="prompt-panel__artist-img"
                                style={{ backgroundImage: `url('${artist.imgSrc}')` }}
                            />
                            <small>{artist.name}</small>
                        </div>
                    ))
                }
            </div>
            <h3 className="prompt-panel__title">
                Prompt
            </h3>
            <small className="prompt-panel__prompt">
                {prompt}
            </small>
            {/* <small>Mood: {mixMusicId}</small> */}
        </div>
    );
}

export default PromptPanel;
