import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import LoginIcon from '@mui/icons-material/Login';
import HomeIcon from '@mui/icons-material/Home';
import Footer from 'components/Footer';
import { drawerMenu } from 'constants/navigation';
import {
    SIGN_IN ,
    HOME
} from 'constants/routes';
import {
    IDrawerMenu, 
    IPlanObject 
} from 'interfaces';
import { 
    isDesktop, 
    isMobile 
} from 'utils';

import './DrawerMenu.scss';

interface IDrawerMenuProps {
    className?: string;
    // eslint-disable-next-line no-unused-vars
    toggleDrawer?: (arg: boolean)=> any;
    isAuthenticated: boolean;
    signOutUser: ()=>any;
}

function DrawerMenu(props: IDrawerMenuProps) {
    const {
        className = '',
        toggleDrawer = ()=>{},
        isAuthenticated = false,
        signOutUser = () => {},
    } = props;


    const navigate = useNavigate();

    const handleClose = ()=>{
        toggleDrawer(false);
    };

    const handleClick = (url = '')=> {
        if (url) {
            navigate(url);
        }
    };

    const handleLogIn = () => {
        navigate(SIGN_IN);
    };

    const handleLogOff = async()=> {
        signOutUser();
    };

    const classNames = `drawer-menu ${isDesktop ? 'drawer-menu--desktop' : ''} ${className}`.trim();

    const boxProps = isMobile && {
        sx: { width: 250 },
        onClick: handleClose,
    };

    let mainMenus: IPlanObject[] = drawerMenu;

    if (isDesktop) {
        mainMenus = mainMenus.map((item)=>({
            title: item.title,
            url: item.url,
        }));
        mainMenus.unshift({
            title: '',
            url: HOME,
            icon: HomeIcon
        });
    }


    return (
        <Box 
            className={classNames}
            role="presentation"
            {...boxProps}
        >
            <List className="drawer-menu__list">
                {mainMenus.map((item = {} as IDrawerMenu) => (
                    <ListItem 
                        key={item.url}
                        disablePadding
                    >
                        <ListItemButton onClick={()=>{handleClick(item.url);}} >
                            {item.icon && (
                                <ListItemIcon className="list-item__icon">
                                    <item.icon />
                                </ListItemIcon>
                            )}
                            {item.title && (
                                <ListItemText 
                                    className="drawer-menu__item-text" 
                                    primary={item.title} 
                                />
                            )}
                        </ListItemButton>
                    </ListItem>
                ))}
                {isDesktop && (
                    <ListItem disablePadding>
                        {!isAuthenticated && (
                            <ListItemButton onClick={handleLogIn}>
                                <ListItemText 
                                    className="drawer-menu__item-text"
                                    primary="Log in" />
                            </ListItemButton>
                        )}
                        {isAuthenticated && (
                            <ListItemButton onClick={handleLogOff}>
                                <ListItemText 
                                    className="drawer-menu__item-text"
                                    primary="Log off" />
                            </ListItemButton>
                        )}
                    </ListItem>
                )}
            </List>

            {isMobile && (
                <>
                    <Divider /> 
                    <List className="drawer-menu__list">
                        <ListItem disablePadding>
                            {!isAuthenticated && (
                                <ListItemButton onClick={handleLogIn}>
                                    <ListItemIcon>
                                        <LoginIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Log in" />
                                </ListItemButton>
                            )}
                            {isAuthenticated && (
                                <ListItemButton onClick={handleLogOff}>
                                    <ListItemIcon>
                                        <LogoutIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Log off" />
                                </ListItemButton>
                            )}
                        </ListItem>
                    </List>
                    <Footer asColumn />
                </>
            )}
        </Box>
    );
}

export default DrawerMenu;
