import { connect } from 'react-redux';
import PlaylistToggle from 'components/PlaylistToggle';
import { IRootState } from 'interfaces/index';


const mapStateToProps = (state: IRootState) => ({ isAuthenticated: !!state.account?.authenticated });

const mapDispatchToProps = () => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);
// @ts-ignore
export default connector(PlaylistToggle);
