import React, { memo } from 'react';


import './ProgressBar.scss';

interface IProgressBarProps {
    className?: string;
    progressBarRef: React.RefObject<any>;
}

function ProgressBar(props: IProgressBarProps) {
    const {
        className = '',
        progressBarRef,
    } = props;

    const classNames = `progress-bar ${className}`.trim();

    return(
        <div className={classNames}>
            <div
                ref={progressBarRef} 
                className="progress-bar__progress"
            />
        </div>
    );
}

export default memo(ProgressBar);
