import jayAitch from '../assets/images/pic_male_rap_1.jpg';
import effEm from '../assets/images/pic_male_rap_2.jpg';
import rulaG from '../assets/images/pic_male_rap_3.jpg';
import audacious from '../assets/images/pic_male_rap_4.jpg';
import kapperKirk from '../assets/images/pic_female_rap_1.jpg';
import olReynolds from '../assets/images/pic_male_rapper_06.jpg';
import dirtydoc from '../assets/images/pic_male_rapper_07.jpg';
import theA from '../assets/images/pic_male_rapper_08.jpg';

export const voiceData = [
    {
        id: '3',
        name: 'Jay Aitch',
        audioSrc: '/assets/voices/rapper_04_ross.mp3',
        imgSrc: audacious ,
    },
    {
        id: '2',
        name: 'EffEm',
        audioSrc: '/assets/voices/rapper_02_joss.mp3',
        imgSrc: rulaG,
    },
    {
        id: '4',
        name: 'Audacious',
        audioSrc: '/assets/voices/rapper_05_bruce.mp3',
        imgSrc: jayAitch,
    },
    {
        id: '1',
        name: 'Rula G',
        audioSrc: '/assets/voices/rapper_01_tom.mp3',
        imgSrc: effEm,
    },
    {
        id: '5',
        name: 'Kapper Kirk',
        audioSrc: '/assets/voices/rapper_fe_02_tina.mp3',
        imgSrc: kapperKirk,
    },
    {
        id: '6',
        name: 'Ol Reynolds',
        audioSrc: '/assets/voices/rapper_06.mp3',
        imgSrc: olReynolds,
    },
    {
        id: '7',
        name: 'Dirtydoc',
        audioSrc: '/assets/voices/rapper_07.mp3',
        imgSrc: dirtydoc,
    },
    {
        id: '8',
        name: 'The A',
        audioSrc: '/assets/voices/rapper_08.mp3',
        imgSrc: theA,
    },
];

export const singerData = [
    {
        id: '1',
        name: 'Kapper Kirk',
        audioSrc: '/assets/voices/rapper_fe_02_tina.mp3',
        imgSrc: kapperKirk,
    }
];

