import { connect } from 'react-redux';
import { IRootState } from 'interfaces/index';
import ProtectedRoute from 'components/ProtectedRoute';

const mapStateToProps = (state: IRootState) => ({ account: state.account });

const mapDispatchToProps = () => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);
// @ts-ignore
export default connector(ProtectedRoute);
