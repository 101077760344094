import { 
    useState,
    useEffect,
    useRef,
} from 'react';

export interface IUseStickyOption {
    buffer: number;
    scrollElement?: HTMLElement | undefined;
    enable?: boolean;
}

function useSticky(opt = {} as IUseStickyOption) {
    const {
        buffer = 0,
        scrollElement = window,
        enable,
    } = opt;

    const [isSticky, setIsSticky] = useState(false);

    const targetRef = useRef<HTMLDivElement>(null);

    useEffect(()=> {
        if (!enable) {
            return undefined;
        }
        const handleScroll = () => {
            if (!targetRef || !targetRef.current) {
                return;
            }

            const stickyPos = targetRef.current.offsetTop + buffer;

            const yposition = scrollElement instanceof HTMLElement
                ? scrollElement.offsetTop
                : scrollElement.scrollY;

            if (yposition > stickyPos) {
                setIsSticky(true);
            } else {
                setIsSticky(false); 
            }
        };

        scrollElement.addEventListener('scroll', handleScroll);

        return function clearUp() {
            scrollElement.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return [
        targetRef,
        isSticky
    ] as const;
}

export default useSticky;
