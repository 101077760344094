import { connect } from 'react-redux';
import PlaylistsScroller from 'components/PlaylistsScroller';

import { IRootState, } from 'interfaces/index';

const mapStateToProps = (state: IRootState) => ({ 
    playlists: state.music?.playlists || {},
    loadPlaylistsStatus: state.music?.loadPlaylistsStatus || '',
});

const mapDispatchToProps = () => ({});

const connector = connect(mapStateToProps, mapDispatchToProps);
// @ts-ignore
export default connector(PlaylistsScroller);
