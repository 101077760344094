/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PauseIcon from '@mui/icons-material/Pause';
import RepeatIcon from '@mui/icons-material/Repeat';
import ShuffleIcon from '@mui/icons-material/Shuffle';

import './Controls.scss';
import { ICurrentMusic } from 'interfaces';

export interface IControlsProps {
    currentMusic: ICurrentMusic;
    showFullControls?: boolean;
    handleNext?: ()=> any;
    handlePrevious?: ()=> any;
    handleRepeat?: ()=> any;
    handlesRepeatSingle?: ()=> any;
    handleShuffle?: ()=> any;
    // eslint-disable-next-line no-unused-vars
    updatePlayerStatus: (arg: boolean) => any;
    autoPlayAllPlaylists?: boolean;
}

function Controls(props: IControlsProps) {
    const {
        currentMusic = {} as ICurrentMusic,
        showFullControls = true,
        handleNext = ()=>{},
        handlePrevious = ()=>{},
        updatePlayerStatus = () => {},
        handleRepeat = () => {},
        handlesRepeatSingle = () => {},
        handleShuffle = () => {},
        autoPlayAllPlaylists = false,
    } = props;

    const {
        index,
        isPlaying,
        isLastMusic,
        isRepeat,
        isShuffle,
    } = currentMusic;

    const [repeatCount, setRepeatCount] = useState(isRepeat ? 1 : 0);

    const togglePlayPause = (e:any) => {
        e.stopPropagation();
        updatePlayerStatus(!isPlaying);
    };

    const handleRepeatMusic = ()=> {
        if (!isRepeat) {
            setRepeatCount(1);
            handleRepeat();
            return;
        }
        const newRepeatCount = repeatCount + 1;
        // repeat same song
        if (newRepeatCount === 2) {
            handlesRepeatSingle();
        }

        if (newRepeatCount > 2) {
            setRepeatCount(0);
            handlesRepeatSingle();
            handleRepeat();
        }

        setRepeatCount(newRepeatCount);
    };

    let disablePrev = false;
    let disableNext = false;

    if (!autoPlayAllPlaylists) {
        if (index === 0) {
            disablePrev = true;
        }
        if (isLastMusic) {
            disableNext = true;
        }
    }

    const isRepeatCurrentSong = repeatCount === 2;

    return (
        <div className="controls">
            <div className="controls__buttons">
                {showFullControls && (
                    <>
                        <IconButton 
                            className={`controls__btn-shuffle ${isShuffle ? 'active' : '' }`}
                            onClick={handleShuffle} 
                            type='button'
                            disabled={isRepeatCurrentSong}
                            aria-label="Shuffle"
                        >
                            <ShuffleIcon />
                        </IconButton>
                        <IconButton 
                            onClick={handlePrevious} 
                            type='button'
                            aria-label="Previous"
                            disabled={disablePrev}
                        >
                            <SkipPreviousIcon />
                        </IconButton>
                    </>
                )}
                <IconButton 
                    className="controls__btn-play"
                    onClick={togglePlayPause}
                    aria-label={`${isPlaying ? 'Pause' : 'Play'}`}
                >
                    {isPlaying ? <PauseIcon /> : <PlayCircleFilledIcon />}
                </IconButton>
                {showFullControls && (
                    <>
                        <IconButton 
                            onClick={handleNext}
                            type='button'
                            aria-label="Next"
                            disabled={disableNext}
                        >
                            <SkipNextIcon />
                        </IconButton>
                        <IconButton 
                            className={`controls__btn-repeat ${isRepeat ? 'active' : ''}`}
                            onClick={handleRepeatMusic} 
                            type='button'
                            aria-label="Repeat"
                        >
                            { isRepeatCurrentSong && (
                                <span className="controls__repeat-single">1</span>
                            )}
                            <RepeatIcon />
                        </IconButton>
                    </>
                )}
            </div>
        </div>
    );
}

export default Controls;
