/* eslint no-unused-vars: 0 */ 
import {
    LOADING_MUSIC,
    LOAD_MUSIC_SUCCESS,
    LOAD_MUSIC_ERROR,
    LOADING_PLAYLIST,
    LOAD_PLAYLIST_SUCCESS,
    LOAD_PLAYLIST_ERROR,
    UPDATE_CURRENT_MUSIC_INDEX,
    UPDATE_LIKE,
    UPDATE_VIEW,
    CREATING_MUSIC,
    CREATE_MUSIC_SUCCESS,
    CREATE_MUSIC_ERROR,
    UPDATE_CREATE_MUSIC_STATUS,
    UPDATE_PLAYER_STATUS,
    UPDATE_PLAYER_OPEN_STATE,
    UPDATE_SLIDE_DIALOG_OPEN_STATE,
    UPDATE_REPEAT_PLAYLIST,
    UPDATE_REPEAT_SINGLE,
    UPDATE_SHUFFLE_PLAYLIST,
    LOADING_PLAYLISTS,
    LOAD_PLAYLISTS_SUCCESS,
    LOAD_PLAYLISTS_ERROR,
    DELETE_PLAYLIST_SUCCESS,
} from 'constants/actionTypes';
import {
    ERROR,
    LOADING,
    SUCCESS,
    SUBMITTING,
    SUBMITTED,
} from 'constants/index';
import { DEFAULT_PLAYLIST_ID } from 'constants/api';
import { 
    IMusicState, 
    ITrack,
    IPendingTrack,
} from 'interfaces/index';

interface IAction {
    type: string,
    payload: any
}

export default (state = {} as IMusicState, action = {} as IAction) => {
    const {
        payload 
    } = action;

    switch (action.type) {
        case LOADING_PLAYLIST: {
            const {
                playlistId
            } = payload;

            if (state.playlists[playlistId]) {
                return state;
            }

            return {
                ...state,
                playlists: {
                    ...state.playlists,
                    [`${playlistId}`]: { status: LOADING }
                }
            };
        }
        case LOAD_PLAYLIST_SUCCESS: {
            const {
                createMusicQueue,
            } = state;

            const {
                playlistId,
                playlistData
            } = payload;

            const tracks = playlistData.musics;
            let newCreateMusicQueue: any[] = [];

            // check and remove musicQueue id
            if (createMusicQueue && createMusicQueue.length) {
                newCreateMusicQueue = [...createMusicQueue];
                createMusicQueue.forEach((item: IPendingTrack, index: number)=>{
                    const isMatchMusicQueueId = tracks.some((track: ITrack) =>  track.musicId === item.musicId);
                    if (isMatchMusicQueueId) {
                        newCreateMusicQueue.splice(index, 1);
                    }
                });
            }

            return {
                ...state,
                playlists: {
                    ...state.playlists,
                    [`${playlistId}`]: playlistData
                },
                createMusicQueue: newCreateMusicQueue,
            };
        }
        case LOAD_PLAYLIST_ERROR: {
            const {
                playlistId
            } = payload;

            return {
                ...state,
                playlists: {
                    ...state.playlists,
                    [`${playlistId}`]: { status: ERROR }
                }
            };
        }
        case LOADING_PLAYLISTS: {
            return {
                ...state,
                loadPlaylistsStatus: LOADING,
            };
        }
        case LOAD_PLAYLISTS_SUCCESS: {
            return {
                ...state,
                playlists: {
                    ...state.playlists,
                    ...payload
                },
                loadPlaylistsStatus: SUCCESS,
            };
        }
        case LOAD_PLAYLISTS_ERROR: {
            return {
                ...state,
                loadPlaylistsStatus: ERROR,
            };
        }
        case LOADING_MUSIC: {
            return {
                ...state,
                musicStatus: LOADING,
            };
        }
        case LOAD_MUSIC_SUCCESS: {
            return {
                ...state,
                musicStatus: SUCCESS,
            };
        }
        case LOAD_MUSIC_ERROR: {
            return {
                ...state,
                musicStatus: ERROR,
            };
        }
        case UPDATE_CURRENT_MUSIC_INDEX: {
            // paylod must contains playlistId
            // musicId or index
            const {
                playlistId,
                musicId,
                index,
            } = payload;

            let newMusicId = musicId || '';
            let newIndex = index;
            let currentPlaylist = state.playlists[playlistId];
            let newPlaylistId = playlistId;

            if (currentPlaylist && currentPlaylist.musics) {
                // change music by index and find the musicId
                if (typeof newIndex === 'number') {
                    newMusicId = currentPlaylist.musics[index]?.musicId;
                } 
                else {
                    // change music by musicId
                    currentPlaylist.musics.find((music: ITrack, idx: number) => {
                        if (music.musicId === musicId) {
                            newIndex = idx;
                            return true;
                        }
                        return false;
                    });
                }
            }
            // an empty playlist
            if (!newMusicId) {
                // do nothing if have previous state of musicId
                if (state.currentMusic.musicId) {
                    return state;
                }
                // fall back to 1st song in default playlist
                currentPlaylist = state.playlists[DEFAULT_PLAYLIST_ID || ''];
                newMusicId = currentPlaylist.musics[index].musicId;
                newIndex = 0;
                newPlaylistId = DEFAULT_PLAYLIST_ID;
            }

            return {
                ...state,
                currentMusic: {
                    ...state.currentMusic,
                    ...payload,
                    playlistId: newPlaylistId,
                    musicId: newMusicId,
                    index: newIndex,
                    isLastMusic: (currentPlaylist.musics.length -1) === newIndex
                }
            };
        }
        case UPDATE_PLAYER_STATUS: {
            return {
                ...state,
                currentMusic: {
                    ...state.currentMusic,
                    isPlaying: payload,
                }
            };
        }
        case UPDATE_PLAYER_OPEN_STATE: {
            if (state.currentMusic.isPlayerOpen === payload) {
                return state;
            }
            return {
                ...state,
                currentMusic: {
                    ...state.currentMusic,
                    isPlayerOpen: payload,
                },
            };
        }
        case UPDATE_SLIDE_DIALOG_OPEN_STATE: {
            return {
                ...state,
                slideDialog: payload,
            };
        }
        case UPDATE_LIKE: {
            const { 
                musicId,
                playlistId,
                liked = false,
            } = payload;

            const playlist = state.playlists[playlistId];

            if (playlist && playlist.musics && musicId) {
                const newMusics = playlist.musics.map((track: ITrack)=>{
                    const currentTrack = track;
                    if (track.musicId === musicId) {
                        if (liked) {
                            currentTrack.likes += 1;
                        }
                        currentTrack.liked = liked;
                    }
                    return track;
                });

                return {
                    ...state,
                    playlists: {
                        ...state.playlists,
                        [`${playlistId}`]: {
                            ...state.playlists[playlistId],
                            musics: newMusics,
                        }
                    }
                };
            }

            return state;
        }
        case UPDATE_VIEW: {
            const { 
                musicId,
                playlistId,
            } = payload;

            const playlist = state.playlists[playlistId];

            if (playlist && playlist.musics && musicId) {
                const newMusics = playlist.musics.map((track: ITrack)=>{
                    if (track.musicId === musicId) {
                        // eslint-disable-next-line no-param-reassign
                        track.views += 1;
                    }
                    return track;
                });

                return {
                    ...state,
                    playlists: {
                        ...state.playlists,
                        [`${playlistId}`]: {
                            ...state.playlists[playlistId],
                            musics: newMusics,
                        }
                    }
                };
            }
            return state;
        }
        case CREATING_MUSIC: {
            return {
                ...state,
                createMusicStatus: SUBMITTING,
            };
        }
        case CREATE_MUSIC_SUCCESS: {
            const {
                musicId = '',
            } = payload;

            // create createMusicQueue state
            const newCreateMusicQueue = state.createMusicQueue
                ? [...state.createMusicQueue]
                : [];

            // only push unique song id to createMusicQueue
            if (musicId) {
                const isDuplicated = newCreateMusicQueue.some(item => item.musicId === musicId);
                if (!isDuplicated) {
                    payload.musicName = `${payload.musicName} (Creating...)`;
                    newCreateMusicQueue.unshift(payload);
                }
            }

            return {
                ...state,
                createMusicQueue: [...newCreateMusicQueue],
                createMusicStatus: SUBMITTED,
            };
        }
        case CREATE_MUSIC_ERROR: {
            return {
                ...state,
                createMusicStatus: ERROR,
            };
        }
        case UPDATE_CREATE_MUSIC_STATUS: {
            return {
                ...state,
                createMusicStatus: payload,
            };
        }
        case UPDATE_REPEAT_PLAYLIST: {
            return {
                ...state,
                currentMusic: {
                    ...state.currentMusic,
                    isRepeat: payload,
                }
            };
        }
        case UPDATE_REPEAT_SINGLE: {
            return {
                ...state,
                currentMusic: {
                    ...state.currentMusic,
                    isRepeatSingle: payload,
                    isShuffle: false,
                }
            };
        }
        case UPDATE_SHUFFLE_PLAYLIST: {
            return {
                ...state,
                currentMusic: {
                    ...state.currentMusic,
                    isShuffle: payload,
                }
            };
        }
        case DELETE_PLAYLIST_SUCCESS: {
            if (!state.playlists[payload]) {
                return state;
            }
            const newPlaylists = { ...state.playlists };
            delete newPlaylists[payload];

            return {
                ...state,
                playlists: newPlaylists,
            };
        }
        default:
            return state;
    }
};
